import React from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { enrollCourse, getUserData } from "../../api/server";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import cross from "../../assets/images/SiteImg/cross.png";
import { login } from "../../store/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import StripeComponent from "../../common/stripeComponent";

const EnrollmentInFirstCourse = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const [getloading, setGetLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [postLoadingFirst, setPostLoadingFirst] = useState(false);
  const [userData, setUserData] = useState();
console.log("userdata>>>>>>",userData,userData?.deliverymethod)
  const handleEnrollFirst = async (values) => {
    setPostLoadingFirst(true);

    let payment_mode = "Credit Card"; 

    if (userData?.google_add == "1") {
      payment_mode = "Google Add";
    } else if (
      userData?.deliverymethod == "bill" ||
      userData?.deliverymethod == "CC"
    ) {
      payment_mode = userData?.deliverymethod;
    } else if (userData?.paid_first == "checked") {
      payment_mode = "Admin manually checked";
    }
    
    const newvalue = {
      ...values,
      payment_mode
    };

    await enrollCourse(
      userId ? "admin" : "user",
      userId ? userId : userData.id,
      newvalue
    )
      .then((res) => {
        if (!userId) {
          dispatch(login(res.data));
        }
        setPostLoadingFirst(false);
        navigate(
          userId
            ? `/user-dashboard-page?id=${userId}`
            : `/security-law-questions?verification=true`
        );
      })
      .catch((err) => {
        setPostLoadingFirst(false);
        if (err.response && err.response.data) {
          toast.error(err.response.data.error);
        } else {
          toast.error("An unexpected error occurred.");
        }
      });
  };

  const handleSubmitFirst = async () => {
    handleEnrollFirst();
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getUserData(userId);
        setUserData(response.data.data.user);
        setGetLoading(false);
      } catch (error) {
        setGetLoading(false);
        console.error("Error fetching data user:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div>
      <Header />

      {showModal && (
        <StripeComponent
          showModal={showModal}
          setShowModal={setShowModal}
          isLoading={postLoadingFirst}
          setIsLoading={setPostLoadingFirst}
          pay={handleEnrollFirst}
          currentCourse="first"
          amount={userData.amount}
        />
      )}
      {getloading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "200px" }}
        >
          <ThreeDots
            height="40"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
            }}
            wrapperClassName="spinner"
            visible={true}
          />
        </div>
      ) : (
        <div className="theme_container">
          <div className="my-profile">
            <h1 id="firstcourse">
              Enroll in <span className="text_success">First Course</span>
            </h1>
          </div>

          <div className="row mt-40">
            <div className="row mt-40"></div>
            <div className="btn_wrap text-center">
              <button
                type="button"
                className=" btn_brnd "
                data-bs-toggle="modal"
                data-bs-target="#nonSerious"
                disabled={postLoadingFirst}
              >
                {postLoadingFirst ? (
                  <ThreeDots
                    height="20"
                    width="150"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Enroll Now"
                )}
              </button>
            </div>
            {/*1st custom Modal */}
            <div
              className={`modal fade custom_modal `}
              id="nonSerious"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="SeriousLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content pb-60">
                  <button
                    type="button"
                    className="btn_close"
                    data-bs-dismiss="modal"
                  >
                    <img src={cross} alt="cross" className="cross_btn" />
                  </button>

                  <div className="modal-body">
                    <div className="modal_content">
                      <h1 className="f-26 text-center">
                        <span className="text_success">Note</span>
                      </h1>
                      {userData?.google_add !=="1" && userData.paid_first === "" &&
                      
                      (userData?.deliverymethod === "client" ||
                        userData?.deliverymethod === "DE" ||
                        userData?.deliverymethod === "")
                      ? (
                        <>
                          {
                          // userData.attypayment == "no" && (
                            <p className="f-16 text-center mt-12 fw-700">
                              The price for the course is
                              <span className="text_success">
                                {" "}
                                $
                                {
                                // userData.attypayment === "no" &&
                                userData.couponcode_first !== ""
                                  ? 39.99
                                  : 19.99}{" "}
                              </span>
                            </p>
                          // )
                          }
                          {/* {
                          // userData.attypayment == null && 
                          
                          userData?.google_add != "1" &&
                          userData?.paid_first !== "checked" &&
                          // userData.couponcode_first !== "" &&
                          (userData?.deliverymethod === "client"  ||
                          userData?.deliverymethod === "DE" || 
                          userData?.deliverymethod === "")  &&
                          
                          (
                            <p className="f-16 text-center mt-12 fw-700">
                              The price for the course is
                              <span className="text_success"> $ 19.99</span>
                            </p>
                          )} */}
                        </>
                      ) : (
                        // userData.attypayment == "no" 
                        // userData?.paid_first == "checked"
                         ( userData?.google_add == "1" ||
                          userData?.paid_first == "checked") &&
                          (userData?.deliverymethod === "client" ||
                          userData?.deliverymethod === "DE" ||
                          userData?.deliverymethod === "" )
                        && (
                          <p className="f-16 text-center mt-12 fw-700">
                            The amount for the course is paid
                          </p>
                        )
                      )}

                      {
                      // userData.attypayment == "yes" 
                      userData?.google_add != "1" &&
                          (userData?.deliverymethod === "bill" ||
                            userData?.deliverymethod === "CC")
                      && (
                        <p className="f-16 text-center mt-12 fw-700">
                          Amount will be paid by your attorney
                        </p>
                      )}
                      {
                      // userData?.google_add !=="1" && userData.paid_first === "" 
                          userData?.google_add != "1" &&
                          userData?.paid_first !== "checked" &&
                          // userData.couponcode_first !== "" &&
                          (userData?.deliverymethod === "client"  ||
                          userData?.deliverymethod === "DE" || 
                          userData?.deliverymethod === "") 
                      
                      ? (
                        <>
                          {userData.totalAmount !== userData.amount &&
                            // userData.attypayment == "no" && 
                            (
                              <p className="f-16 text-center fw-700">
                                Amount Payable
                                <span className="text_success">
                                  {" "}
                                  ${Number(userData.amount).toFixed(2)}{" "}
                                </span>
                              </p>
                            )}
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="btn_wrap text-center mt-40">
                        <button
                          className="btn_brnd-outline mr-22 mb-2"
                          data-bs-dismiss="modal"
                          type="button"
                        >
                          Go Back
                        </button>

                        <button
                          className=" btn_brnd  mt-20"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            if (userData.paid_first === "") {
                              if (
                                // userData.attypayment === "no" 
                                (userData?.deliverymethod === "client" ||
                                  userData?.deliverymethod === "DE"
                                || userData?.deliverymethod === "")
                                &&
                                userData.amount > 0
                              ) {
                                setShowModal(true);
                              } else {
                                handleSubmitFirst();
                              }
                            } else {
                              handleSubmitFirst();
                            }
                          }}
                          type={
                            (
                              // userData.attypayment === "yes" &&
                              (userData?.deliverymethod === "bill" ||
                                userData?.deliverymethod === "CC") &&
                              userData.amount === 0) ||
                            userData.paid_first !== ""
                              ? "submit"
                              : "button"
                          }
                        >
                          Confirm & Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default EnrollmentInFirstCourse;
