import React, { useState, useEffect } from "react";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { toast } from "react-toastify";
import { getAllCert, getAllCertRetake, getFollowup, getFollowupRetake, getIncompleteCertificates } from "../../api/server";
import PaginatedItems from "./pagination";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import moment from "moment";

const Certificates = () => {
  const [getloading, setGetLoading] = useState(true);
  const [response, setResponse] = useState([]);
  const [pageCache, setPageCache] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();
  const [getloadingFollow, setGetLoadingFollow] = useState(true);
  const [responseFollow, setResponseFollow] = useState([]);
  const [pageCacheFollow, setPageCacheFollow] = useState({});
  const [pageNoFollow, setPageNoFollow] = useState(1);
  const [totalFollow, setTotalFollow] = useState();

  const [getloadingRetake, setGetLoadingRetake] = useState(true);
  const [responseRetake, setResponseRetake] = useState([]);
  const [pageCacheRetake, setPageCacheRetake] = useState({});
  const [pageNoRetake, setPageNoRetake] = useState(1);
  const [totalRetake, setTotalRetake] = useState();
  const [getloadingFollowRetake, setGetLoadingFollowRetake] = useState(true);
  const [responseFollowRetake, setResponseFollowRetake] = useState([]);
  const [pageCacheFollowRetake, setPageCacheFollowRetake] = useState({});
  const [pageNoFollowRetake, setPageNoFollowRetake] = useState(1);
  const [totalFollowRetake, setTotalFollowRetake] = useState();

  const [pageNoIncompleteCerts, setPageNoIncompleteCerts] = useState(1);
const [pageCacheIncompleteCerts, setPageCacheIncompleteCerts] = useState({});
const [incompleteCertsData, setIncompleteCertsData] = useState([]);
const [totalIncompleteCerts, setTotalIncompleteCerts] = useState(0);
const [loadingIncompleteCerts, setLoadingIncompleteCerts] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (pageCacheFollow[pageNoFollow]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCacheFollow[pageNoFollow];
        setResponseFollow(cachedResponse);
        setTotalFollow(cachedTotal);
      } else {
        setGetLoadingFollow(true);
        await getFollowup(pageNoFollow)
          .then((res) => {
            const ungroupData = res.data.data.followUp.result;

            const groupedById = {};

            ungroupData.forEach((obj) => {
              if (!groupedById[obj.id]) {
                groupedById[obj.id] = [];
              }
              groupedById[obj.id].push(obj);
            });

            const newData = Object.values(groupedById);
            const newTotal = res.data.data.followUp.total;

            // Update the cache with new data
            setPageCacheFollow((prevCache) => ({
              ...prevCache,
              [pageNoFollow]: {
                cachedResponse: newData,
                cachedTotal: newTotal,
              },
            }));

            // Set state with new data
            setResponseFollow(newData);
            setTotalFollow(newTotal);
            setGetLoadingFollow(false);
          })
          .catch((err) => {
            toast.error(err.response?.data.error);
            setGetLoadingFollow(false);
          });
      }
    };

    fetchData();
  }, [pageNoFollow, pageCacheFollow]);
  
  useEffect(() => {
    const fetchIncompleteCerts = async () => {
      if (pageCacheIncompleteCerts[pageNoIncompleteCerts]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCacheIncompleteCerts[pageNoIncompleteCerts];
        setIncompleteCertsData(cachedResponse);
        setTotalIncompleteCerts(cachedTotal);
      } else {
        setLoadingIncompleteCerts(true);
        await getIncompleteCertificates(pageNoIncompleteCerts)
        // await getAllCert(pageNoIncompleteCerts)

          .then((res) => {
            const ungroupedData = res.data.data.followUpWithOutInfo.result;
  
            const groupedById = {};
            ungroupedData.forEach((user) => {
              if (!groupedById[user.id]) {
                groupedById[user.id] = [];
              }
              else{
                user.type = 'Joint'
              }
              groupedById[user.id].push(user);
            });
            console.log("asdkfjaks",groupedById,res)
  
            const newData = Object.values(groupedById);
            const newTotal = res.data.data.followUpWithOutInfo.total;
  
            // Update the cache with new data
            setPageCacheIncompleteCerts((prevCache) => ({
              ...prevCache,
              [pageNoIncompleteCerts]: {
                cachedResponse: newData,
                cachedTotal: newTotal,
              },
            }));
  
            setIncompleteCertsData(newData);
            setTotalIncompleteCerts(newTotal);
            setLoadingIncompleteCerts(false);
          })
          .catch((err) => {
            toast.error(err.response?.data.error || "Failed to fetch report data");
            setLoadingIncompleteCerts(false);
          });
      }
    };
  
    fetchIncompleteCerts();
  }, [pageNoIncompleteCerts, pageCacheIncompleteCerts]);
  

  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setResponse(cachedResponse);
        setTotal(cachedTotal);
      } else {
        setGetLoading(true);
        await getAllCert(pageNo)
          .then((res) => {
            const newTotal = res.data.data.certificate.total;

            const ungroupData = res.data.data.certificate.result;

            const groupedById = {};

            ungroupData.forEach((obj) => {
              if (!groupedById[obj.id]) {
                groupedById[obj.id] = [];
              }
              groupedById[obj.id].push(obj);
            });

            const newData = Object.values(groupedById);
            console.log(newData);

            // Update the cache with new data
            setPageCache((prevCache) => ({
              ...prevCache,
              [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
            }));

            // Set state with new data
            setResponse(newData);
            setTotal(newTotal);
            setGetLoading(false);
          })
          .catch((err) => {
            toast.error(err.response?.data.error);
            setGetLoading(false);
          });
      }
    };

    fetchData();
  }, [pageNo, pageCache]);


  useEffect(() => {
    const fetchData = async () => {
      if (pageCacheFollowRetake[pageNoFollowRetake]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCacheFollowRetake[pageNoFollowRetake];
        setResponseFollowRetake(cachedResponse);
        setTotalFollowRetake(cachedTotal);
      } else {
        setGetLoadingFollowRetake(true);
        await getFollowupRetake(pageNoFollowRetake)
          .then((res) => {
            const ungroupData = res.data.data.followUp.result;

            const groupedById = {};

            ungroupData.forEach((obj) => {
              if (!groupedById[obj.id]) {
                groupedById[obj.id] = [];
              }
              groupedById[obj.id].push(obj);
            });

            const newData = Object.values(groupedById);
            const newTotal = res.data.data.followUp.total;

            // Update the cache with new data
            setPageCacheFollow((prevCache) => ({
              ...prevCache,
              [pageNoFollowRetake]: {
                cachedResponse: newData,
                cachedTotal: newTotal,
              },
            }));

            // Set state with new data
            setResponseFollowRetake(newData);
            setTotalFollowRetake(newTotal);
            setGetLoadingFollowRetake(false);
          })
          .catch((err) => {
            toast.error(err.response?.data.error);
            setGetLoadingFollowRetake(false);
          });
      }
    };

    fetchData();
  }, [pageNoFollowRetake, pageCacheFollowRetake]);

  useEffect(() => {
    const fetchData = async () => {
      if (pageCacheRetake[pageNoRetake]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCacheRetake[pageNoRetake];
        setResponseRetake(cachedResponse);
        setTotalRetake(cachedTotal);
      } else {
        setGetLoadingRetake(true);
        await getAllCertRetake(pageNoRetake)
          .then((res) => {
            const newTotal = res.data.data.certificate.total;

            const ungroupData = res.data.data.certificate.result;

            const groupedById = {};

            ungroupData.forEach((obj) => {
              if (!groupedById[obj.id]) {
                groupedById[obj.id] = [];
              }
              groupedById[obj.id].push(obj);
            });

            const newData = Object.values(groupedById);
            console.log(newData);

            // Update the cache with new data
            setPageCacheRetake((prevCache) => ({
              ...prevCache,
              [pageNoRetake]: { cachedResponse: newData, cachedTotal: newTotal },
            }));

            // Set state with new data
            setResponseRetake(newData);
            setTotalRetake(newTotal);
            setGetLoadingRetake(false);
          })
          .catch((err) => {
            toast.error(err.response?.data.error);
            setGetLoadingRetake(false);
          });
      }
    };

    fetchData();
  }, [pageNoRetake, pageCacheRetake]);

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          <section className="manage-students language_wrap">
            <h4 className="f26 ">Follow-ups</h4>
            <div className="card  mt-20">
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Client Name</th>
                      <th scope="col">State</th>
                      <th scope="col">Follow-up Date</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloadingFollow ? (
                      <tr>
                        <td colSpan={4}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : responseFollow && responseFollow.length > 0 ? (
                      responseFollow.map((users, ind) =>
                        users.map((follow, index) => (
                          <tr key={index}>
                            <th
                              scope="row"
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              <div className="flex-i gAddBox__row">
                                {" "}
                                {follow.type === "Joint"
                                  ? follow.joint_firstname === "" &&
                                    follow.joint_lastname === ""
                                    ? follow.firstname +
                                    " " +
                                    follow.lastname +
                                    "'s Joint"
                                    : follow.joint_firstname +
                                    " " +
                                    follow.joint_lastname +
                                    " (Joint)"
                                  : follow.firstname + " " + follow.lastname}
                                {follow?.googleADs == true ? <span className="gAddBox flex-a">AD</span> : ""}
                              </div>
                            </th>
                            <td
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {follow.state}
                            </td>
                            <td
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: follow.followup,
                              }}
                            ></td>
                            <td
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              <Link
                                className="text-black"
                                to={`/followup?id=${follow.id}&courseId=${follow.courseId}`}
                              >
                                Edit
                              </Link>
                            </td>
                          </tr>
                        ))
                      )
                    ) : (
                      <tr>
                        <td colSpan={4}>No followup found</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {!getloadingFollow && totalFollow !== 0 && totalFollow > 10 && (
                  <PaginatedItems
                    total={totalFollow}
                    pageNo={pageNoFollow}
                    setPageNo={setPageNoFollow}
                  />
                )}
              </div>
            </div>
          </section>
          <section className="manage-students language_wrap mt-4">
  <h4 className="f26">Incomplete Certificates</h4>
  <div className="card mt-20">
    <div className="card-body" style={{ overflowX: "auto" }}>

      <table className="table table-responsive">
        <thead>
          <tr>
            <th scope="col">User Name</th>
            <th scope="col">Email</th>
            <th scope="col">CC Enrolled Date</th>
            <th scope="col">DE Enrolled Date</th>
            <th scope="col">Course Name</th>
            

            {/* <th scope="col">Certificate Issued</th> */}
            <th scope="col">Action</th>
          </tr>
        </thead>
       
        <tbody >
          {loadingIncompleteCerts ? (
            <tr>
              <td colSpan={6}>
                <ThreeDots
                  height="40"
                  width="80"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  wrapperClassName="spinner"
                  visible={true}
                />
              </td>
            </tr>
          ) : incompleteCertsData && incompleteCertsData.length > 0 ? (
            // incompleteCertsData.map((users, ind) =>
              incompleteCertsData.map((users, ind) =>
                users.map((follow, index) => (
                  <tr key={index}>
                    
                    <th
                      scope="row"
                      style={{
                        borderBottomWidth:
                          index !== users.length - 1 ? "0px" : "",
                      }}
                    >
                      <div className="flex-i gAddBox__row">
                        {" "}
                        {follow.type === "Joint"
                          ? follow.joint_firstname === "" &&
                            follow.joint_lastname === ""
                            ? follow.firstname +
                            " " +
                            follow.lastname +
                            "'s Joint"
                            : follow.joint_firstname +
                            " " +
                            follow.joint_lastname +
                            " (Joint)"
                          : follow.firstname + " " + follow.lastname}
                        {follow?.googleADs == true ? <span className="gAddBox flex-a">AD</span> : ""}
                      </div>
                    </th>
                    <td
                      style={{
                        borderBottomWidth:
                          index !== users.length - 1 ? "0px" : "",
                      }}
                    >
                      {follow.email}
                    </td>

                    <td
                      style={{
                        borderBottomWidth:
                          index !== users.length - 1 ? "0px" : "",
                      }}
                    >
                  
                    {follow.Enrolled_in_CC &&  moment(follow.Enrolled_in_CC).format("MMMM Do YYYY") }

                    </td>

                    <td
                      style={{
                        borderBottomWidth:
                          index !== users.length - 1 ? "0px" : "",
                      }}
                    >
                    {follow.Enrolled_in_DE && moment(follow.Enrolled_in_DE).format("MMMM Do YYYY") }

                    </td>
                    
                    <td
                      style={{
                        borderBottomWidth: index !== users.length - 1 ? "0px" : "",
                        fontWeight: 'bold',
                        color: '#333', 
                        padding: '5px', 
                      }}
                    >
                      {(follow.CC.toLowerCase() === "checked" && follow.judicialdistrict_state_first == "") &&  (
                        <>
                         <span style={{ fontSize: '14px' }}>CC</span>
                         <span style={{ fontSize: '14px' }}>{follow.DE.toLowerCase() === "checked" && follow.judicialdistrict_state_second == "" && " & "}</span>
                        </>
                       
                      )}

                      {(follow.DE.toLowerCase() === "checked" && follow.judicialdistrict_state_second == "") && (
                        <>
                          <span style={{ fontSize: '14px' }}>DE</span>
                          
                          
                        </>
                      )}
                    </td>
                   
                    <td
                      style={{
                        borderBottomWidth:
                          index !== users.length - 1 ? "0px" : "",
                      }}
                    >
                      <Link
                        className="text-black"
                        to={`/editstudent?id=${follow.id}`}
                      >
                        View Profile
                      </Link>
                    </td>
                  </tr>
                ))
              )
          ) : (
            <tr>
              <td colSpan={6}>No incomplete certificates found</td>
            </tr>
          )}
        </tbody>
      </table>

      {!loadingIncompleteCerts && totalIncompleteCerts > 10 && (
        <PaginatedItems
          total={totalIncompleteCerts}
          pageNo={pageNoIncompleteCerts}
          setPageNo={setPageNoIncompleteCerts}
        />
      )}
    </div>
  </div>
</section>
          <section className="manage-students language_wrap mt-5">
            <h4 className="f26 ">DE Certificates</h4>

            <div className="card  mt-20">
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Client Name</th>
                      <th scope="col">Course 1</th>
                      <th scope="col">Course 2</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloading ? (
                      <tr>
                        <td colSpan={4}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : response && response.length > 0 ? (
                      response.map((users, ind) =>
                        users.map((cert, index) => (
                          <tr key={index}>
                            <th
                              scope="row"
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              <div className="flex-i gAddBox__row">
                                {cert.courseFirstType === "Joint" ||
                                  cert.courseSecondType === "Joint"
                                  ? cert.joint_firstname === "" &&
                                    cert.joint_lastname === ""
                                    ? cert.firstname +
                                    " " +
                                    cert.lastname +
                                    "'s Joint"
                                    : cert.joint_firstname +
                                    " " +
                                    cert.joint_lastname +
                                    " (Joint)"
                                  : cert.firstname + " " + cert.lastname}
                                {cert?.googleADs == true ? <span className="gAddBox flex-a">AD</span> : ""}
                              </div>
                            </th>
                            <td
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {cert.courseFirstAud18 ||
                                cert.courseFirstCompleted !== "" ? (
                                cert.courseFirstGenerated !== "" ? (
                                  cert.courseFirstDownloaded &&
                                    cert.courseFirstDownloaded !== "yes" ? (
                                    <a
                                      target="_blank"
                                      href={cert.courseFirstDownloaded}
                                      className="text-black"
                                    >
                                      {cert.courseFirstDate
                                        ? cert.courseFirstDate.split("T")[0]
                                        : ""}
                                    </a>
                                  ) : cert.courseFirstDate ? (
                                    <span className="tooltips">
                                      {cert.courseFirstDate.split("T")[0]}
                                    </span>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  "Pending"
                                )
                              ) : cert.courseFirstDate &&
                                cert.courseFirstGenerated === "" ? (
                                ""
                              ) : (
                                ""
                              )}
                            </td>
                            <td
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {" "}
                              {cert.courseSecondAud49 !== "" ||
                                cert.courseSecondCompleted !== "" ? (
                                cert.courseSecondGenerated !== "" ? (
                                  cert.downloaded &&
                                    cert.downloaded !== "yes" ? (
                                    <a
                                      target="_blank"
                                      href={cert.downloaded}
                                      className="text-black"
                                    >
                                      {cert.courseSecondDate
                                        ? cert.courseSecondDate.split("T")[0]
                                        : ""}
                                    </a>
                                  ) : cert.courseSecondDate ? (
                                    <span className="tooltips">
                                      {cert.courseSecondDate.split("T")[0]}
                                    </span>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  "Pending"
                                )
                              ) : cert.courseSecondDate &&
                                cert.courseSecondGenerated === "" ? (
                                ""
                              ) : (
                                ""
                              )}
                            </td>
                            <td
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              <Link
                                className="text-black"
                                to={`/editstudent?id=${cert.id}&from=cert`}
                              >
                                Edit
                              </Link>
                            </td>
                          </tr>
                        ))
                      )
                    ) : (
                      <tr>
                        <td colSpan={4}>No certificates found</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {!getloading && total !== 0 && total > 50 && (
                  <PaginatedItems
                    total={total}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                  />
                )}
              </div>
            </div>
          </section>
          <section className="manage-students language_wrap">
            <h4 className="f26 ">Re-Take Follow-ups</h4>
            <div className="card  mt-20">
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Client Name</th>
                      <th scope="col">State</th>
                      <th scope="col">Follow-up Date</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloadingFollowRetake ? (
                      <tr>
                        <td colSpan={4}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : responseFollowRetake && responseFollowRetake.length > 0 ? (
                      responseFollowRetake.map((users, ind) =>
                        users.map((follow, index) => (
                          <tr key={index}>
                            <th
                              scope="row"
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              <div className="flex-i gAddBox__row">
                                {" "}
                                {follow.type === "Joint"
                                  ? follow.joint_firstname === "" &&
                                    follow.joint_lastname === ""
                                    ? follow.firstname +
                                    " " +
                                    follow.lastname +
                                    "'s Joint"
                                    : follow.joint_firstname +
                                    " " +
                                    follow.joint_lastname +
                                    " (Joint)"
                                  : follow.firstname + " " + follow.lastname}
                                {follow?.googleADs == true ? <span className="gAddBox flex-a">AD</span> : ""}
                              </div>
                            </th>
                            <td
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {follow.state}
                            </td>
                            <td
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: follow.followup,
                              }}
                            ></td>
                            <td
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              <Link
                                className="text-black"
                                to={`/followup?id=${follow.id}&courseId=${follow.courseId}&retake=true`}
                              >
                                Edit
                              </Link>
                            </td>
                          </tr>
                        ))
                      )
                    ) : (
                      <tr>
                        <td colSpan={4}>No followup found</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {!getloadingFollowRetake && totalFollowRetake !== 0 && totalFollowRetake > 10 && (
                  <PaginatedItems
                    total={totalFollowRetake}
                    pageNo={pageNoFollowRetake}
                    setPageNo={setPageNoFollowRetake}
                  />
                )}
              </div>
            </div>
          </section>
          <section className="manage-students language_wrap mt-5">
            <h4 className="f26 ">Re-Take Certificates</h4>

            <div className="card  mt-20">
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Client Name</th>
                      <th scope="col">Course 1</th>
                      <th scope="col">Course 2</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloadingRetake ? (
                      <tr>
                        <td colSpan={4}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : responseRetake && responseRetake.length > 0 ? (
                      responseRetake.map((users, ind) =>
                        users.map((cert, index) => (
                          <tr key={index}>
                            <th
                              scope="row"
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              <div className="flex-i gAddBox__row">
                                {cert.courseFirstType === "Joint" ||
                                  cert.courseSecondType === "Joint"
                                  ? cert.joint_firstname === "" &&
                                    cert.joint_lastname === ""
                                    ? cert.firstname +
                                    " " +
                                    cert.lastname +
                                    "'s Joint"
                                    : cert.joint_firstname +
                                    " " +
                                    cert.joint_lastname +
                                    " (Joint)"
                                  : cert.firstname + " " + cert.lastname}
                                {cert?.googleADs == true ? <span className="gAddBox flex-a">AD</span> : ""}
                              </div>
                            </th>
                            <td
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {cert.courseFirstAud18 ||
                                cert.courseFirstCompleted !== "" ? (
                                cert.courseFirstGenerated !== "" ? (
                                  cert.courseFirstDownloaded &&
                                    cert.courseFirstDownloaded !== "yes" ? (
                                    <a
                                      target="_blank"
                                      href={cert.courseFirstDownloaded}
                                      className="text-black"
                                    >
                                      {cert.courseFirstDate
                                        ? cert.courseFirstDate.split("T")[0]
                                        : ""}
                                    </a>
                                  ) : cert.courseFirstDate ? (
                                    <span className="tooltips">
                                      {cert.courseFirstDate.split("T")[0]}
                                    </span>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  "Pending"
                                )
                              ) : cert.courseFirstDate &&
                                cert.courseFirstGenerated === "" ? (
                                ""
                              ) : (
                                ""
                              )}
                            </td>
                            <td
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {" "}
                              {cert.courseSecondAud49 !== "" ||
                                cert.courseSecondCompleted !== "" ? (
                                cert.courseSecondGenerated !== "" ? (
                                  cert.downloaded &&
                                    cert.downloaded !== "yes" ? (
                                    <a
                                      target="_blank"
                                      href={cert.downloaded}
                                      className="text-black"
                                    >
                                      {cert.courseSecondDate
                                        ? cert.courseSecondDate.split("T")[0]
                                        : ""}
                                    </a>
                                  ) : cert.courseSecondDate ? (
                                    <span className="tooltips">
                                      {cert.courseSecondDate.split("T")[0]}
                                    </span>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  "Pending"
                                )
                              ) : cert.courseSecondDate &&
                                cert.courseSecondGenerated === "" ? (
                                ""
                              ) : (
                                ""
                              )}
                            </td>
                            <td
                              style={{
                                borderBottomWidth:
                                  index !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              <Link
                                className="text-black"
                                to={`/editstudent?id=${cert.id}&from=cert`}
                              >
                                Edit
                              </Link>
                            </td>
                          </tr>
                        ))
                      )
                    ) : (
                      <tr>
                        <td colSpan={4}>No certificates found</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {!getloadingRetake && totalRetake !== 0 && totalRetake > 50 && (
                  <PaginatedItems
                    total={totalRetake}
                    pageNo={pageNoRetake}
                    setPageNo={setPageNoRetake}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Certificates;
